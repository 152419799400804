<template>
  <div class="group-content">
    <Carousel></Carousel>
    <Slider></Slider>
    <button class="btn-group-reservation" @click="$router.push('/reservas')">
      <span>Reserva Ahora</span>
    </button>
  </div>
</template>
<script>
import Carousel from '@/components/Template/Carousel.vue';
import Slider from '@/components/Template/Slider.vue';
export default {
  components: {
    Carousel,
    Slider
  }
};
</script>
<style lang="scss" scoped>
.group-content {
  display: flex;
  align-items: center;
  flex-direction: column;
  .btn-group-reservation {
    width: 220px;
    height: 40px;
    flex-shrink: 0;
    border-radius: 8px;
    background: #000;
    margin-bottom: 50px;
    margin-top: 50px;
    @media screen and (min-width: 1024px) and (max-width: 1279px) {
      margin-top: 30px;
    }
    cursor: pointer;
    span {
      color: #FFF;
      text-align: center;
      font-family: 'Open Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
}
</style>
