<template>
  <div class="carousel-wrapper">
    <carousel :perPage="perPage" :autoplay="true" :paginationPadding="5">
      <slide v-for="(slide, index) in slides" :key="'Slide-' + index">
        <img :src="slide.image" :alt="'Slide ' + (index + 1)" class="slider-image">
      </slide>
    </carousel>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';

export default {
  components: {
    Carousel,
    Slide
  },
  data () {
    return {
      slides: [
        { image: '/images/new-dlk/logos/primi.svg' },
        { image: '/images/new-dlk/logos/casa.svg' },
        { image: '/images/new-dlk/logos/dilucca.svg' },
        { image: '/images/new-dlk/logos/cosette.svg' },
        { image: '/images/new-dlk/logos/patria.svg' },
        { image: '/images/new-dlk/logos/gamberro.svg' },
        { image: '/images/new-dlk/logos/gitane.svg' }
      ],
      perPage: 1
    };
  },
  mounted () {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  methods: {
    handleResize () {
      const screenWidth = window.innerWidth;
      if (screenWidth >= 1440) {
        this.perPage = 7;
      } else if (screenWidth >= 1280) {
        this.perPage = 6;
      } else if (screenWidth >= 1024) {
        this.perPage = 5;
      } else if (screenWidth >= 768) {
        this.perPage = 4;
      } else {
        this.perPage = 3;
      }
    }
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize);
  }
};
</script>

<style lang="scss">
.carousel-wrapper {
  width: calc(100% - 200px);
  margin-top: 30px;
  overflow-x: auto;
  @media screen and (min-width: 1024px) and (max-width: 1279px) {
    width: calc(100% - 30px);
  }
  @media screen and (max-width: 1023px) {
    width: calc(100% - 20px);
  }
  .VueCarousel {
    .VueCarousel-slide {
      display: flex;
      align-items: center;
      justify-content: center;
      .slider-image {
        width: auto;
        height: auto;
        @media screen and (max-width: 767px) {
          width: 70%;
          height: 70%;
        }
      }
    }
  }
}
</style>
